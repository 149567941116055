'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import './MealTitle.scss';
import FoodUnitsSelector from '../DailyLog/FoodUnitsSelectorModal.react';

export default class MealTitle extends Component {
    static contextTypes = {
        recipes: PropTypes.object,
        foods: PropTypes.object
    };

    constructor(props, context) {
        super(props, context);
    }

    getBrandName(meal, foods = {}) {
        if(meal?.meal_type === 'food' && foods[meal?.food_uuid]) {
            return foods[meal?.food_uuid]?.brand_name;
        }
    }

    render() {
        const { foods } = this.context;
        const { item, meal, nutrientsIndex, hideBrandName = false, readOnly = true } = this.props;

        let itemPrice = null;
        let subtitle = (!hideBrandName && meal) ? this.getBrandName(meal, foods) : null;

        if (item.mapped_from?.length && item.servings) {
            const mf = item.mapped_from.find((mf) => mf.price);

            if (mf) {
                itemPrice = mf.price / item.servings;
            }
        }

        if (itemPrice == null && item.avg_cost_per_serving) {
            itemPrice = item.avg_cost_per_serving;
        }

        return (
            <div className="meal-title-container">
                <div className="meal-title-content">
                    <h5 className="meal-title">{item.pretty_name || item.name || item.title}</h5>
                </div>

                {subtitle ? <div className="meal-subtitle">{subtitle}</div> : null}

                {meal ? (
                    <div className="food-units">
                        <FoodUnitsSelector
                            food={item}
                            meal={meal}
                            readOnly={readOnly}
                            nutrientsIndex={nutrientsIndex}
                        />
                    </div>
                ) : null}

                {itemPrice ? (
                    <div className="price-per-serving">
                        <p className="t4">${itemPrice.toFixed(2)}/SERVING EST</p>
                    </div>
                ) : null}
            </div>
        );
    }
}
