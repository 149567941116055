'use strict';

import { pluralize, singularize } from 'inflected';
import { Fraction } from 'fractional';

export function roundForHumans(value) {
    if (value == 0) {
        return 0;
    }

    if (value > 0 && value < 0.009) {
        return Math.round(value * 10000) / 10000;
    }

    if (value >= 0.009 && value < 0.09) {
        return Math.round(value * 1000) / 1000;
    }

    if (value >= 0.09 && value < 5) {
        return Math.round(value * 100) / 100;
    }

    if (value > 5 && value < 10) {
        return Math.round(value * 10) / 10;
    }

    return Math.round(value);
}

export function humanizeMilliliters(ml) {
    if (ml <= 750) {
        return roundForHumans(ml) + " ml";
    }

    let l = roundForHumans(ml / 1000);

    return l + (l == 1 ? " liter" : " liters");
}

export function humanizeGrams(grams) {
    if (grams <= 750) {
        return roundForHumans(grams) + " grams";
    }

    let kg = roundForHumans(grams / 1000);

    return kg + (kg == 1 ? " kilogram" : " kilograms");
}

export function roundForHumansByUnit(value, unit) {
    if (['g', 'mg', 'ug', "\u03bcg", "IU"].includes(unit)) {
        return Math.round(value);
    }

    if (['servings', ' servings'].includes(unit)) {
        // Round to the nearest quarter
        return Math.round(value * 4) / 4;
    }

    return roundForHumans(value);
}

export function isSingular(value) {
    return value > 0 && value <= 1;
}

export function inflectGroceryWeight(amount, description) {
    let suffix = '';

    // Don't inflect these ever
    if (description === 'fluid oz') {
        return description;
    }

    if (description.indexOf(',') > 0) {
        let splitName = description.split(',').map(v => v.trim()).filter(v => v);
        description = splitName.shift();
        suffix = splitName.length > 0 ? (', ' + splitName.join(', ')) : '';
    }

    if (isSingular(amount)) {
        description = singularize(description || 'item').toLowerCase();
    } else {
        description = pluralize(description || 'item').toLowerCase();
    }

    return description + suffix;
}

export function inflectUnitOfMeasure(amount, unit) {
    let { description } = unit;
    let suffix = '';

    if (!description) {
        return '';
    }

    if (['fluid oz', 'fluid oz.', 'fl oz', 'fl oz.', 'fl. oz', 'fl. oz.'].includes(description)) {
        return 'fluid oz';
    }

    if (description.indexOf(',') > 0) {
        let splitName = description.split(',', 1);
        suffix = description.substring(description.indexOf(','));
        description = splitName.shift().trim();
    } else if (description.indexOf('(') > 0) {
        let splitName = description.split('(', 1);
        suffix = ' ' + description.substring(description.indexOf('('));
        description = splitName.shift().trim();
    }

    const singularToPluralExceptions = {
        'leaf': 'leaves',
        'cookie': 'cookies',
    };

    const pluralToSingularException = {
        'leaves': 'leaf',
        'cookies': 'cookie',
    };

    if (isSingular(amount)) {
        if (pluralToSingularException[description]) {
            return pluralToSingularException[description] + suffix;
        }

        if (!unit.plural) {
            return singularize(description || 'item').toLowerCase() + suffix;
        }
    } else {
        if (singularToPluralExceptions[description]) {
            return singularToPluralExceptions[description] + suffix;
        }

        return unit.plural || (pluralize(description || 'item').toLowerCase() + suffix);
    }

    return description + suffix;
}


export function smartCeil(value) {
    // Separate the value into its whole and fractional parts
    var whole = Math.floor(value);
    var fraction = value - whole;

    var rounds = [
        // Fractions to round to
        Math.round(fraction * 2) / 2,  // 1/2
        Math.round(fraction * 3) / 3,  // 1/3, 2/3
        Math.round(fraction * 4) / 4,  // 1/4, 1/2, 3/4
        Math.round(fraction * 5) / 5,  // 1/5, 2/5, 3/5
        Math.round(fraction * 6) / 6,  // 1/6, 1/3, 5/6
        Math.round(fraction * 8) / 8,  // 1/8, ..., 7/8
    ];

    // Find the closest fraction to the actual value
    var closest = rounds.reduce((a, b) => {
        return Math.abs(b - fraction) < Math.abs(a - fraction) ? b : a;
    });

    // Return the whole part plus the closest fraction
    return whole + closest;
}

export function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}

export function getKmBetweenCoordinates(lat1, lon1, lat2, lon2) {
    var earthRadiusKm = 6371;

    var dLat = degreesToRadians(lat2 - lat1);
    var dLon = degreesToRadians(lon2 - lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadiusKm * c;
}

export function convertDecimalToFraction(value) {
    // Limit our resolution to 1/1000s (bonus: also filters against XSS)
    let amount = Math.round(value * 1000) / 1000;

    // Extract the fractional component
    let whole = Math.floor(amount), numerator = 0, denominator = 0;
    let fractional = amount - whole;

    // let returnEntity = (numerator, denominator) => {
    //     return "<sup>"+numerator+"</sup>&frasl;<sub>"+denominator+"</sub>";
    // }

    if (Math.round(fractional * 100) == 33) {
        fractional = 1/3; // add some more precision
    }

    if (Math.round(fractional * 100) == 66 ||
        Math.round(fractional * 100) == 67) {
        fractional = 2/3; // add some more precision
    }

    if (fractional >= .95) {
        fractional = 0;
        whole += 1;
    }

    // If we don't have a fractional amount, then just return the whole amount
    if (fractional == 0) {
        return {whole};
    }

    if (Math.round(fractional * 100) == 33) {
        numerator = 1;
        denominator = 3;
    } else if (Math.round(fractional * 100) == 67 || Math.round(fractional * 100) == 66) {
        numerator = 2;
        denominator = 3;
    } else if (Math.round(fractional * 1000) == 167 || Math.round(fractional * 1000) == 166) {
        numerator = 1;
        denominator = 6;
    } else if (Math.round(fractional * 100) == 83) {
        numerator = 5;
        denominator = 6;
    } else {
        try {
            // Convert the fractional using the fractional library
            var f = new Fraction(fractional);
            numerator = f.numerator;
            denominator = f.denominator;
        } catch(e) {
            // Sometimes Fractional library throws ugly exceptions :(
        }
    }

    return { whole, numerator, denominator };
}
