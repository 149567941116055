'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import allNutrients from '../../../../../tables/nutrients';

import modalStyles from '../../../../../jsx-styles/modals';
import './AddNutrients.scss';

export default class AddNutrients extends Component {

    static contextTypes = {
        showUpgradeForm: PropTypes.func,
        user: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            selectedNutrients: [],
            isModalOpen: false,
        };
    }

    focused = null;

    autofocus = (el) => {
        if (el && !this.focused) {
            this.focused = el;
            el.focus();
            el.select();
        }

        // Component unmounted. Goodbye.
        if (!el) {
            this.focused = null;
        }
    }

    showModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false, selectedNutrients: []});
    }

    toggleNutrient = (nutrNo) => {
        const { selectedNutrients } = this.state;
        const { showUpgradeForm} = this.context;

        if (this.checkLocked(nutrNo)) {
            showUpgradeForm({feature: 'edit_micros'});
            return;
        }

        const i = selectedNutrients.indexOf(nutrNo);

        if (i === -1) {
            selectedNutrients.push(nutrNo);
        } else {
            selectedNutrients.splice(i, 1);
        }

        this.setState({selectedNutrients});
    }

    matchFilters = (nutrNo) => {
        const { currentNutrients = [] } = this.props;
        const { search } = this.state;

        if (currentNutrients.includes(nutrNo)) {
            return false;
        }

        if (!(allNutrients[nutrNo] && allNutrients[nutrNo].Filter)) {
            return false;
        }

        // Do we have a search parameter active?
        if (search) {
            const index = [
                nutrNo,
                allNutrients[nutrNo].NutrDesc,
            ].map(s => s.toLowerCase()).join(' ');

            return index.indexOf(search.toLowerCase()) !== -1;
        }

        return true;
    }

    checkLocked = (nutrNo) => {
        const { user } = this.context;
        const { capabilities = {}} = user || {};
        const macros = ['203', '204', '205', '208'];
        return (macros.includes(nutrNo) && !capabilities.edit_macros) || (!macros.includes(nutrNo) && !capabilities.edit_micros);
    }

    renderAddNutrientsModal = () => {
        const { isModalOpen, search, selectedNutrients } = this.state;
        const { currentNutrients = [], onAddNutrients } = this.props;

        if (!isModalOpen) {
            return null;
        }

        const alphaSortCmp = (a, b) => allNutrients[a].NutrDesc.localeCompare(allNutrients[b].NutrDesc);

        const nutrients = Object.keys(allNutrients)
                                .filter(this.matchFilters)
                                .sort(alphaSortCmp);

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className="add-remove-nutrients-modal"
                contentLabel="Add/Remove Nutrients"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="add-remove-nutrients-container editor-modal-container">
                    <header className="modal-header">
                        <h2>Add Nutrients</h2>
                        <button className="close-btn" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="search">
                        <input type="text" placeholder="Search"
                            ref={this.autofocus}
                            value={search}
                            onChange={ev => this.setState({search: ev.target.value})} />
                    </div>

                    <div className="editor-scrollable">
                        <ul>
                            {nutrients.map(nutrNo => {
                                return (
                                    <li key={nutrNo}>
                                        <label className="global-search-checkbox">
                                            <input type="checkbox" checked={selectedNutrients.includes(nutrNo)}
                                                onChange={e => this.toggleNutrient(nutrNo)} />
                                            <span>
                                                {allNutrients[nutrNo].NutrDesc}
                                                {this.checkLocked(nutrNo) ? <i className="icon-lock" /> : null}
                                            </span>
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <footer>
                        <button className="cancel" onClick={this.closeModal}>Cancel</button>
                        <button className="accept" onClick={() => onAddNutrients(selectedNutrients) || this.closeModal()}>Add</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render() {

        return (
            <span className="add-remove-nutrients">
                <button className="nutrient-btn" onClick={this.showModal}>
                    Add Nutrient

                </button>

                {this.renderAddNutrientsModal()}
            </span>
        );
    }
}
