'use strict';

import moment from 'moment';
import { pluralize, singularize } from 'inflected';
import qs from 'qs';

import { roundForHumans, smartCeil, isSingular } from './Math';
import { getConfig } from './Env';

import groceryCategories from '../tables/grocery-categories';
const freshCategories = groceryCategories.filter(c => c.fresh).map(c => c.label);

import allProviders from '../tables/fulfillment-providers';

export function flattenIngredients(details) {
    const ingredients = [];

    Object.keys(details).map(key => details[key]).forEach(detail => {
        detail.ingredients.forEach(group => {
            group.items.forEach(ingredient => {
                ingredients.push(ingredient);
            });
        });
    });

    return ingredients;
}

export function addGroceryFromFood(groceries, food, grams, milliliters, meals = [], ingredients = []) {
    const grocery = groceries[food.uuid] = groceries[food.uuid] || {
        food_uuid: food.uuid,
        type: food.type,
        name: food.pretty_name || food.name || food.title,
        brand_name: food.brand_name,
        category: food.category,
        image: food.image,
        serving_unit: food.serving_unit,
        refuse: food.refuse || 0,
        grams_needed: 0,
        milliliters_needed: 0,
        exclude_from_groceries: food.exclude_from_groceries,
        meals: [],
        ingredients: [],
        count: 0,
        quantity: 1,
    };

    grocery.count++;
    grocery.meals = grocery.meals.concat(meals);
    grocery.ingredients = grocery.ingredients.concat(ingredients);

    const scaling = meals.reduce((carry, meal) => {
        if (meal.meal_type !== 'food') {
            return;
        }

        // We already calculated how many we need and stored it in scaling
        return carry + meal.scaling;
    }, 0) || 1;

    if (grams) {
        grocery.grams_needed += grams;
        grocery.used_price = (food.standard_price / 100) * grocery.grams_needed;
    } else if (milliliters) {
        grocery.milliliters_needed += milliliters;
        grocery.used_price = (food.standard_price / 100) * grocery.milliliters_needed;
    } else if (food.servings) {
        grocery.quantity = Math.ceil(scaling / food.servings);
    }

    // just in case
    groceries[food.uuid] = grocery;

    return groceries;
}

export function addGroceryFromFoodIngredient(groceries, ingredient, scaling, food, meals) {
    if (!food) {
        return groceries;
    }

    let neededGrams, neededMl;

    if (food.serving_unit === 'ml' && ingredient.milliliters) {
        neededMl = ingredient.milliliters  * (scaling || 1);
    } else if (ingredient.grams) {
        neededGrams = ingredient.grams * (scaling || 1);
    }

    return addGroceryFromFood(groceries, food, neededGrams, neededMl, meals, [ingredient]);
}

export function getBestPackage(grams, milliliters, food, units = 'metric') {
    const gramsWithRefuse = Math.ceil(grams * (1 / (1 - (food.refuse || 0) / 100)));
    const millilitersWithRefuse = Math.ceil(milliliters * (1 / (1 - (food.refuse || 0) / 100)));
    // Next, we need to determine what how much of each package size the user would buy
    let packages = (food.packaging || []).map((pkg) => {
        if (!pkg.grams && !pkg.milliliters) {
            return {
                quantity: 1,
                grams: 0,
                milliliters: 0,
                amount: 1,
                excess: 99999, // we want to use these last
                description: pkg.description,
                mode: pkg.mode,
                display_mode: pkg.display_mode,
            };
        }

        let quantity;
        let excess;
        let pkgDescription = pkg.description;
        let pkgGrams = pkg.grams;
        let pkgMl = pkg.milliliters;
        let pkgAmount = pkg.amount;

        if (food.serving_unit == 'ml') {
            quantity = roundForHumans(Math.ceil(millilitersWithRefuse / pkgMl));
            excess = quantity * pkgMl - milliliters;
        } else {
            // Is this continuous or discrete? If its discrete, ceiling
            // to the next integer, otherwise, clamp to the next highest 1/8th
            if (pkg.mode == 'continuous') {
                if ((pkgDescription === 'kilogram' || pkgDescription === 'kilograms') && grams < 750) {
                    pkgGrams = 1;
                    pkgAmount = 1;
                    pkgDescription = grams == 1 ? 'gram' : 'grams';
                }

                // Round continuously measured items to 1/10 if metric, 1/8th if not
                quantity = units === 'metric'
                        ? roundForHumans(gramsWithRefuse / pkgGrams)
                        : Math.ceil(gramsWithRefuse / pkgGrams * 8) / 8;
            } else {
                quantity = Math.ceil(gramsWithRefuse / pkgGrams);
            }

            excess = quantity * pkgGrams - gramsWithRefuse;

            if (excess / pkgGrams > 0.85 && quantity > 1) {
                quantity--;
                // Recalculate excess now, should be under 0.1
                excess = quantity * pkgGrams - gramsWithRefuse;
            }
        }

        return {
            quantity,
            excess,
            grams: pkgGrams,
            milliliters: pkgMl,
            amount: pkgAmount,
            description: pkgDescription,
            mode: ['discrete', 'continuous'].includes(pkg.mode) ? pkg.mode : 'discrete', // default to discrete
            units: pkg.units,
            display_mode: pkg.display_mode,
        };
    });

    packages = packages.sort((a, b) => {
        if (units === 'metric' && a.units == 'metric' && b.units != 'metric') return -1;
        if (units === 'metric' && a.units != 'metric' && b.units == 'metric') return 1;
        if (units !== 'metric' && a.units == 'metric' && b.units != 'metric') return 1;
        if (units !== 'metric' && a.units != 'metric' && b.units == 'metric') return -1;

        // Always prioritize discrete packaging over continuous,
        // more stores sell packaged goods than loose goods on average.
        if (a.mode == 'discrete' && b.mode == 'continuous') return -1;
        if (a.mode == 'continuous' && b.mode == 'discrete') return 1;

        // Deprioritize bulk preferred items as well
        if (a.bulk_preferred && !b.bulk_preferred) return 1;
        if (!a.bulk_preferred && b.bulk_preferred) return -1;

        // First sort by which packaging has the least excess
        if (a.excess > b.excess) return 1;
        if (a.excess < b.excess) return -1;

        // Now sort by largest package weight (so user buys the least quantity)
        if (food.serving_unit === 'g' && a.grams > b.grams) return -1;
        if (food.serving_unit === 'g' && a.grams < b.grams) return 1;

        if (food.serving_unit === 'ml' && a.milliliters > b.milliliters) return -1;
        if (food.serving_unit === 'ml' && a.milliliters < b.milliliters) return 1;

        return 0;
    });

    return packages[0];
}

export function updateGroceryPackaging(groceries, foods = {}, units = 'metric') {
    // Figure out how much waste there would be if they purchased the food specifically for these meals
    groceries = groceries.map(grocery => {

        grocery.pkg_grams = grocery.pkg_grams || 0;
        grocery.price = grocery.price || 0;
        grocery.excess = grocery.excess || 0;
        grocery.excess_price = grocery.excess_price || 0;

        let food = grocery.food_uuid && foods[grocery.food_uuid];

        if (food) {
            // Next, we need to determine what how much of each package size the user would buy
            let pkg = getBestPackage(grocery.grams_needed, grocery.milliliters_needed, food, units);
            if (pkg) {
                grocery.quantity     = pkg.quantity;
                grocery.mode         = pkg.mode;
                grocery.display_mode = pkg.display_mode;
                grocery.description  = grocery.weight = pkg.description;
                grocery.pkg_grams    = pkg.grams;
                grocery.pkg_milliliters = pkg.milliliters;
                grocery.price        = grocery.quantity * (pkg.grams || pkg.milliliters) * (food.standard_price / 100) / (pkg.amount || 1);

                grocery.excess       = pkg.excess;
                grocery.excess_price = (food.standard_price / 100) * pkg.excess;
            } else if (units === 'metric' && food.serving_unit === 'ml' && grocery.milliliters_needed) {
                grocery.display_mode = 'prefix';
                grocery.description = grocery.weight = 'ml';
                grocery.quantity = grocery.milliliters_needed;
            } else if (units === 'metric' && food.serving_unit === 'g' && grocery.grams_needed) {
                grocery.display_mode = 'prefix';
                grocery.description = grocery.weight = 'g';
                grocery.quantity = grocery.grams_needed;
            } else if (units === 'english' && food.serving_unit === 'ml' && grocery.milliliters_needed) {
                grocery.display_mode = 'prefix';
                grocery.description = grocery.weight = 'fluid oz';
                grocery.quantity = Math.round(grocery.milliliters_needed / 29.5735);
            } else if (units === 'english' && food.serving_unit === 'g' && grocery.grams_needed) {
                grocery.display_mode = 'prefix';
                grocery.description = grocery.weight = 'ounces';
                grocery.quantity = Math.round(grocery.grams_needed / 28.3495);
            }
        }

        return grocery;
    });

    return groceries;
}

/**
 * Return a list of groceries calculated for the meals.
 *
 * @param  {[type]} meals            UGC meals or plan.items (the schema should be kept similar enough to work)
 * @param  {[type]} options.recipes  recipes indexed by uuid
 * @param  {[type]} options.details  details indexed by uuid
 * @param  {[type]} options.foods    foods indexed by uuid
 * @return {[type]}                  [description]
 */
export function getGroceriesForMeals(meals, {recipes = {}, details = {}, foods = {}}) {
    let groceries = {};
    let subrecipes = {};

    // Loop through each recipe in the plan and aggregate its ingredients
    // into the collector array: groceries
    meals.forEach(meal => {
        let {
            scaling = 1, meal_type,
            recipe_uuid, details_uuid, food_uuid,
            logged_grams, logged_milliliters,
        } = meal;

        // Only consider fresh meals and foods
        if (meal_type === 'food' && foods[food_uuid]) {
            // Foods _always_ come with logged grams or milliliters
            groceries = addGroceryFromFood(groceries, foods[food_uuid], logged_grams, logged_milliliters, [meal]);
        }

        if (meal_type === 'fresh') {
            const recipe = recipes[recipe_uuid];
            const detail = details[details_uuid];

            if (!recipe || !detail) {
                return;
            }

            flattenIngredients({detail}).forEach(ingredient => {
                if (ingredient.food && ingredient.food.uuid && foods[ingredient.food.uuid]) {
                    const food = foods[ingredient.food.uuid];

                    groceries = addGroceryFromFoodIngredient(groceries, ingredient, scaling, food, [meal]);
                }

                if (ingredient.recipe && ingredient.recipe.uuid) {
                    const subrecipe = subrecipes[ingredient.recipe.uuid];

                    // We need to count up how much of each of the subrecipes
                    // are needed
                    if (subrecipe) {
                        subrecipe.grams += (ingredient.grams || 0) * scaling;
                        subrecipe.meals.push(meal);
                    } else {
                        subrecipes[ingredient.recipe.uuid] = {
                            grams: (ingredient.grams || 0) * scaling,
                            meals: [meal],
                        };
                    }
                }
            });
        }
    });

    // Now loop through the subrecipes and add them as well
    Object.keys(subrecipes).forEach(uuid => {
        let subrecipe = recipes[uuid];
        let subdetail = subrecipe && details[subrecipe.details];
        const { grams, meals } = subrecipes[uuid];

        // We cannot add if we're missing data...
        if (!subrecipe || !subdetail) {
            return;
        }


        // How many batches to do we need to make?
        let scaling = grams / (subrecipe.grams_per_serving * (subrecipe.servings || 1));
        scaling = scaling > 0 ? Math.ceil(scaling) : Math.floor(scaling);

        flattenIngredients({subdetail}).forEach(subingredient => {
            if (subingredient.food && subingredient.food.uuid) {
                const food = foods[subingredient.food.uuid];

                groceries = addGroceryFromFoodIngredient(groceries, subingredient, scaling, food, meals);
                return;
            }
        });
    });

    // Get just the values, update packaging, then sort
    groceries = Object.keys(groceries).map(k => groceries[k]);

    // Sort by category, then name
    groceries = groceries.sort((a, b) => {
        if (a.category > b.category) return 1;
        if (a.category < b.category) return -1;

        if (a.name > b.name) return 1;
        if (b.name < b.name) return -1;

        return 0;
    });

    return groceries;
}

export function areMealsInGroceries(meals, groceries, skipChecked = false) {
    if (!(meals instanceof Array)) {
        meals = [meals];
    }

    return meals.every(meal => isMealInGroceries(meal, groceries, skipChecked));
}

/**
 * By searching for the meals UUID in the grocery list, this function is effective
 * but inefficient. Use it, but use it sparingly.
 *
 * @bool skipChecked - if true, then grocery.status = 'purchased' items will not be evaluated.
 */
export function isMealInGroceries(meal, groceries, skipChecked = false) {
    let inGroceries = false;

    (groceries || []).forEach(grocery => {
        // Skip deleted items.
        if (grocery.deleted) {
            return;
        }

        if (skipChecked && grocery.status === 'purchased') {
            return;
        }

        if (!inGroceries && (grocery.meal_uuids || '').indexOf(meal.uuid) != -1) {
            inGroceries = true;
        }
    });

    return inGroceries;
}

export function areMealsGroceriesPurchased(meals, groceries, allMealOrders) {
    if (!(meals instanceof Array)) {
        meals = [meals];
    }

    return meals.every(meal => isMealGroceryPurchased(meal, groceries, allMealOrders));
}

export function isMealGroceryPurchased(meal, groceries, allMealOrders) {
    if (!meal || !meal['uuid']) {
        return false;
    }

    let isPurchased = groceries.filter(p =>
        (p.meal_uuids || '').indexOf(meal['uuid']) != -1 && !p.deleted
    ).every(p => p.status === 'purchased');

    if(!isMealInGroceries(meal, groceries)) {
        isPurchased = false;
    }

    if (meal?.orders && meal.orders.length > 0) {
        const purchasedOrderStatus = ['ACCEPTED', 'IN_PROCESS', 'PROCESSED', 'CANCELLED', 'ERROR', 'ERROR_CANCELLED'];
        const orders = meal.orders.map(order_uuid => {
            return allMealOrders.find(p => p.uuid == order_uuid) ?? [];
        }).filter(p => p);

        return (orders.every(p => p && p.status && purchasedOrderStatus.includes(p.status))) || isPurchased;
    }

    return isPurchased;
}

export function isMealOrderPurchased(meal, allMealOrders) {
    if (!meal.orders) {
        return false;
    }

    const statusOrdered = ['ACCEPTED', 'IN_PROCESS', 'PROCESSED', 'CANCELLED', 'ERROR', 'ERROR_CANCELLED'];
    const orders = meal.orders.map(order_uuid => {
        return allMealOrders.find(p => p.uuid == order_uuid);
    });
    return orders.every(p => statusOrdered.includes(p?.status));
}

export function areGroceriesOutOfDate(meals, groceries) {
    if (groceries.length == 0) {
        return false;
    }

    const now = moment();

    // If there are recognized past-date meals
    // in the grocery list meal uuid references, then the grocery lists are out of date.
    let pastMealUuids = meals.filter(m => moment(m.date).isBefore(now, 'day')).map(m => m.uuid);
    let groceryMealUuids = [];

    groceries.forEach(grocery => {
        if (!grocery.meal_uuids || grocery.deleted) {
            return;
        }

        grocery.meal_uuids.split(',').forEach(uuid => {
            if (groceryMealUuids.indexOf(uuid) === -1) {
                groceryMealUuids.push(uuid);
            }
        });
    });

    let intersect = groceryMealUuids.filter(uuid => pastMealUuids.indexOf(uuid) !== -1);

    if (intersect.length > 0) {
        return true;
    }

    return false;
}

/**
 * Returns the subset of meals that have been added to the grocery list
 *
 * @param  {[type]} meals     The full set of meals
 * @param  {[type]} groceries The grocery list
 * @return {[type]}           The subset of meals that are in groceries
 */
export function getMealsInGroceries(meals, groceries)
{
    // Get all the meal IDs from the grocery items.
    let mealIds = [];
    groceries.forEach(grocery => {
        (grocery.meal_uuids || '').split(',').filter(v => v).forEach(uuid => {
            if (!mealIds.includes(uuid)) {
                mealIds.push(uuid);
            }
        });
    });

    // Isolate the meals from the grocery list
    const mealsInGroceryList = meals.filter(m => mealIds.includes(m.uuid));

    return mealsInGroceryList;
}

export function constructInstacartUrls(groceries, isoCode = null, postalCode = null, instacartStore = null) {
    const pxfUrls = [];

    let ingredients = [], quantities = [];

    const urlencode = (i) => {
        return encodeURIComponent(i.replace(/[()]/g, " ").replace(/\s+/g, " ").trim());
    }

    let instacartDomain = (isoCode || '').toLocaleLowerCase() === 'ca'
        ? 'www.instacart.ca'
        : 'www.instacart.com';

    const encodeInstacartUrl = (ingredients, ingredient = null) => {
        let instacartUrl = [
            `https://${instacartDomain}/store/partner_recipe?`,
            (ingredients.length > 0 ? (ingredients.map(i => 'ingredients[]=' + urlencode(i)).join('&')) : null),
            (ingredient ? '&ingredients[]=' + urlencode(ingredient) : null),
            '&title=EatLove+Groceries',
            '&utm_source=instacart_growth_partnerships',
            '&utm_medium=partner_recipe_unknown',
            '&recipeSourceOrigin=unknown',
            '&aff_id=3106',
            '&offer_id=1',
            '&affiliate_platform=recipe_widget',
            '&image_url=' + encodeURIComponent('https://static.chewba.info/images/43e18011-05cd-4902-b3a7-569d491817d4.png'),
            allProviders[instacartStore]?.instacart ? `&store_id=${allProviders[instacartStore]?.instacart.store_id}` : false,
            postalCode ? ('&current_zip_code=' + encodeURIComponent(postalCode)) : false,
            allProviders[instacartStore]?.instacart ? `&current_retailer_id=${allProviders[instacartStore]?.instacart.current_retailer_id}` : false,
        ].filter(v => v);

        return instacartUrl.join('');
    }

    // Loop through each ingredient and add it to our URL until we get too long, then start another URL.
    for (const i in groceries) {
        let { grocery, food } = groceries[i];
        let ingredient = grocery.grocery;
        let weight = (grocery.weight || '').split(' '),
            amount = Math.ceil(grocery.quantity);

        // If there is a parenthesis in there, we inflect the first word
        if (weight.indexOf('each') !== -1) {
            weight = [];
        } else if (grocery.weight && grocery.weight.indexOf('(') != -1) {
            weight[0] = isSingular(grocery.quantity)
                      ? singularize(weight[0])
                      : pluralize(weight[0]);
        } else if (weight) {
            // Otherwise we inflect the last word
            weight[weight.length - 1] = isSingular(grocery.quantity)
                      ? singularize(weight[weight.length - 1])
                      : pluralize(weight[weight.length - 1]);
        }

        if (food) {
            ingredient = (food.grocery_name || food.pretty_name || food.name) + ', QTY NEEDED: ' + amount + ' ' + weight.join(' ');
        }

        let testUrl = encodeInstacartUrl(ingredients, ingredient);

        if (testUrl.length > 5056) {
            pxfUrls.push(testUrl);

            ingredients = [ingredient];
        } else {
            ingredients.push(ingredient);
        }
    }

    if (ingredients.length) {
        pxfUrls.push(encodeInstacartUrl(ingredients));
    }

    return pxfUrls;
}

export function constructAmazonFreshUrl(groceries, almBrandId) {
    const proxyUrls = [];

    let ingredients = [];

    const encodeAmazonUrl = (ingredients, almBrandId, ingredient = null) => {
        return getConfig('users_api') + `/amazon-proxy.php?almBrandId=${almBrandId}&ingredients=` + encodeURIComponent(
            JSON.stringify({ingredients: ingredients.concat([ingredient]).filter(v => v)})
        );
    }

    const continuousUnits = {
        'count': 'count',
        'kilograms': 'kilograms',
        'kilogram': 'kilograms',
        'grams': 'grams',
        'gram': 'grams',
        'pounds': 'pounds',
        'pound': 'pounds',
        'ounces': 'ounces',
        'ounce': 'ounces',
        'oz': 'ounces',
        'liters': 'liters',
        'liter': 'liters',
        'l': 'liters',
        'milliliters': 'milliliters',
        'milliliter': 'milliliters',
        'ml': 'milliliters',
        'gallons': 'gallons',
        'gallon': 'gallons',
        'quarters': 'quarters',
        'quarter': 'quarters',
        'pints': 'pints',
        'pint': 'pints',
        'cups': 'cups',
        'cup': 'cups',
        'fl_oz': 'fl_oz',
        'tbsp': 'tbsp',
        'tablespoon': 'tbsp',
        'tablespoons': 'tbsp',
        'tsp': 'tsp',
        'teaspoons': 'tsp',
        'teaspoon': 'tsp',
    };

    for (const i in groceries) {
        let { grocery, food } = groceries[i];

        let ingredient = {
            name: grocery.grocery,
            staple: !freshCategories.includes(grocery.category),
            quantityList: [],
        };

        if (grocery.mode === 'continuous' && continuousUnits[grocery.weight]) {
            ingredient.quantityList.push({unit: continuousUnits[grocery.weight], amount: grocery.quantity});
        } else if (grocery.mode === 'continuous' && !continuousUnits[grocery.weight]) {
            ingredient.quantityList.push({unit: 'count', amount: Math.ceil(grocery.quantity)});
        } else if (grocery.mode === 'discrete' && continuousUnits[grocery.weight]) {
            ingredient.quantityList.push({unit: continuousUnits[grocery.weight], amount: grocery.quantity});
        } else {
            ingredient.quantityList.push({unit: 'count', amount: Math.ceil(grocery.quantity)});
        }

        if (grocery.grams_needed > 0) {
            ingredient.quantityList.push({unit: 'grams', amount: Math.ceil(grocery.grams_needed)});
        }

        if (food) {
            ingredient.name = food.grocery_name || food.pretty_name || food.name;
        }

        const testUrl = encodeAmazonUrl(ingredients, almBrandId, ingredient);

        if (testUrl.length > 7750) {
            proxyUrls.push(encodeAmazonUrl(ingredients, almBrandId, null));

            ingredients = [ingredient];
        } else {
            ingredients.push(ingredient);
        }
    }

    if (ingredients.length) {
        proxyUrls.push(encodeAmazonUrl(ingredients, almBrandId, null));
    }

    return proxyUrls;
}


export function constructWalmartUrls(results, ap) {
    const gotoSvcUrls = [];

    const encodeWalmartUrl = (items, item = null) => {

        const cartQuery = {
            ap: ap.accessPointId,
            storeId: ap.fulfillmentStoreId,
            offers: items.concat([item]).filter(v => v && v.product)
                          .map(({product, quantity}) => quantity > 1
                                                      ? `${product.id}_${quantity}`
                                                      : product.id)
                          .join(','),
        };
        const cartSvcUrl = 'https://affil.walmart.com/cart/addToCart?' + qs.stringify(cartQuery);

        const gotoQuery = {
            veh: 'aff',
            sourceid: 'imp_000011112222333344',
            subId3: 'impact_interstitial',
            u: cartSvcUrl,
        };

        return 'https://goto.walmart.com/c/2676849/1207219/9383?' + qs.stringify(gotoQuery);
    }

    let items = [];

    for (const i in results) {
        let { product, quantity } = results[i];

        if (!product) {
            continue;
        }

        const testUrl = encodeWalmartUrl(items, {product, quantity});

        if (testUrl.length > 10750) {
            gotoSvcUrls.push(testUrl);
            items = [{product, quantity}];
        } else {
            items.push({product, quantity});
        }
    }

    if (items.length > 0) {
        gotoSvcUrls.push(encodeWalmartUrl(items));
    }

    return gotoSvcUrls;
}
