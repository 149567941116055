'use strict'

// File generated by bin/sync-static-tables.php
// DO NOT EDIT! (Edit the original copy in recipe/config/table/units.php)

export default {
    "en": [
        {
            "description": "fluid oz",
            "plural": "fluid oz",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "fluid oz",
                "fluid ounce",
                "fluid ounces",
                "fl oz",
                "floz",
                "fl. oz",
                "fl oz.",
                "oza",
                "fluid ounce (us)"
            ],
            "milliliters": 29.5735296,
            "translations": {
                "fr": {
                    "singular": "once fluide",
                    "plural": "onces fluide"
                }
            }
        },
        {
            "description": "teaspoon",
            "plural": "teaspoons",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "teaspoon",
                "teaspoons",
                "tsp",
                "tsp.",
                "tsps"
            ],
            "milliliters": 4.92892159375,
            "translations": {
                "fr": {
                    "singular": "cuill\u00e8re \u00e0 caf\u00e9",
                    "plural": "cuill\u00e8res \u00e0 caf\u00e9"
                }
            }
        },
        {
            "description": "tablespoon",
            "plural": "tablespoons",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "tablespoon",
                "tablespoons",
                "tbsp",
                "tbsps",
                "tbsp.",
                "tbsps.",
                "tbsp mix",
                "tbsp. mix",
                "rounded tbsp"
            ],
            "milliliters": 14.786764782728676,
            "translations": {
                "fr": {
                    "singular": "cuill\u00e8re \u00e0 soupe",
                    "plural": "cuill\u00e8res \u00e0 soupe"
                }
            }
        },
        {
            "description": "cup",
            "plural": "cups",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "cup",
                "cups",
                "cup as packaged",
                "cup prepared",
                "cup dry",
                "cup (1 nlea serving)",
                "cup (1 serving)",
                "cup (1\" cubes)",
                "cup (1\" pieces)",
                "cup (1\/2\" pieces)",
                "cup (8 fl oz)",
                "cup (unprepared)",
                "cup 1 serving",
                "cup 1\" pieces",
                "cup 1\/2\" pieces",
                "cup 8 fl oz",
                "cup chopped",
                "cup chopped or dice",
                "cup chopped or diced",
                "cup diced",
                "cup pieces",
                "cup, pieces",
                "cup pieces or slices",
                "cup, small pieces",
                "cup sections, with juice",
                "cup shredded",
                "cup, shredded",
                "cup sliced",
                "cup, sliced",
                "cup slices",
                "cup, slices",
                "cup thawed",
                "cup, thawed",
                "cup unthawed",
                "cup, unthawed",
                "cup whole",
                "cup, whole",
                "cup, whole or halves",
                "cup chopped",
                "cup, chopped",
                "cup chopped or diced",
                "cup, chopped or diced",
                "cup chunks",
                "cup, chunks",
                "cup crumbled",
                "cup, crumbled",
                "cup, crushed, sliced, or chunks",
                "cup, solid or chunks, drained",
                "cup, cubed",
                "cup, cubes",
                "cup, diced",
                "cup, drained",
                "cup, frozen",
                "cup, grated",
                "cup, halves",
                "cup, halves and whole",
                "cup, halves or slices",
                "cup, loosely packed",
                "cup, mashed",
                "cup, packed",
                "cup, pitted",
                "cup, pulp",
                "cup, sifted",
                "cup, stirred",
                "c",
                "c."
            ],
            "milliliters": 236.5882365,
            "translations": {
                "fr": {
                    "singular": "tasse",
                    "plural": "tasses"
                }
            }
        },
        {
            "description": "pint",
            "plural": "pints",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "pt",
                "pt.",
                "pts",
                "pts."
            ],
            "milliliters": 473.176,
            "translations": {
                "fr": {
                    "singular": "pinte",
                    "plural": "pintes"
                }
            }
        },
        {
            "description": "quart",
            "plural": "quarts",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "quart",
                "quarts",
                "qt",
                "qt."
            ],
            "milliliters": 946.353,
            "translations": {
                "fr": {
                    "singular": "quart",
                    "plural": "quarts"
                }
            }
        },
        {
            "description": "gallon",
            "plural": "gallons",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "gallon",
                "gallons",
                "gal",
                "gal."
            ],
            "milliliters": 3785.41,
            "translations": {
                "fr": {
                    "singular": "gallon",
                    "plural": "gallons"
                }
            }
        },
        {
            "description": "milliliter",
            "plural": "milliliters",
            "units_mode": "metric",
            "type": "volume",
            "alts": [
                "milliliter",
                "milliliters",
                "ml",
                "ml."
            ],
            "milliliters": 1,
            "translations": {
                "fr": {
                    "singular": "millilitre",
                    "plural": "millilitres"
                }
            }
        },
        {
            "description": "centiliter",
            "plural": "centilitres",
            "units_mode": "metric",
            "type": "volume",
            "alts": [
                "centiliter",
                "centiliters",
                "cl",
                "cl."
            ],
            "milliliters": 100,
            "translations": {
                "fr": {
                    "singular": "centilitre",
                    "plural": "centilitres"
                }
            }
        },
        {
            "description": "liter",
            "plural": "liters",
            "units_mode": "metric",
            "type": "volume",
            "alts": [
                "liter",
                "liters",
                "l",
                "l."
            ],
            "milliliters": 1000,
            "translations": {
                "fr": {
                    "singular": "litre",
                    "plural": "litres"
                }
            }
        },
        {
            "description": "kilogram",
            "plural": "kilograms",
            "units_mode": "metric",
            "type": "mass",
            "alts": [
                "kilogram",
                "kilograms",
                "kilo",
                "kilos",
                "kiloes",
                "kg",
                "kg.",
                "kgs",
                "kgs."
            ],
            "grams": 1000,
            "translations": {
                "fr": {
                    "singular": "kilogramme",
                    "plural": "kilogrammes"
                }
            }
        },
        {
            "description": "gram",
            "plural": "grams",
            "units_mode": "metric",
            "type": "mass",
            "alts": [
                "gram",
                "grams",
                "grm",
                "grm.",
                "g",
                "g.",
                "gms",
                "gms.",
                "gm",
                "gm."
            ],
            "grams": 1,
            "translations": {
                "fr": {
                    "singular": "gramme",
                    "plural": "grammes"
                }
            }
        },
        {
            "description": "pound",
            "plural": "pounds",
            "units_mode": "english",
            "type": "weight",
            "alts": [
                "pound",
                "pounds",
                "lb",
                "lbs",
                "lb.",
                "lbs."
            ],
            "acceleration": 9.80665,
            "grams": 453.592,
            "translations": {
                "fr": {
                    "singular": "livre",
                    "plural": "livres"
                }
            }
        },
        {
            "description": "ounce",
            "plural": "ounces",
            "units_mode": "english",
            "type": "weight",
            "alts": [
                "ounce",
                "ounces",
                "oz",
                "ozs",
                "ozs.",
                "oz.",
                "onz",
                "oz dry mix"
            ],
            "acceleration": 9.80665,
            "grams": 28.3495,
            "translations": {
                "fr": {
                    "singular": "once",
                    "plural": "onces"
                }
            }
        }
    ],
    "fr": [
        {
            "description": "oz liquide",
            "plural": "oz liquide",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "oz liquide",
                "once fluide",
                "fluid oz",
                "fluid ounce",
                "fluid ounces",
                "fl oz",
                "fl. oz",
                "fl oz.",
                "oza"
            ],
            "milliliters": 29.5735296,
            "translations": {
                "en": {
                    "singular": "fluid oz",
                    "plural": "fluid oz"
                }
            }
        },
        {
            "description": "cuill\u00e8re \u00e0 caf\u00e9",
            "plural": "cuill\u00e8res \u00e0 caf\u00e9",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "cuill\u00e8re \u00e0 caf\u00e9",
                "cuill\u00e8res \u00e0 caf\u00e9",
                "c. \u00e0 c.",
                "c. a c.",
                "c. \u00e0 c..",
                "c. a c.."
            ],
            "milliliters": 4.92892159375,
            "translations": {
                "en": {
                    "singular": "teaspoon",
                    "plural": "teaspoons"
                }
            }
        },
        {
            "description": "cuill\u00e8re \u00e0 soupe",
            "plural": "cuill\u00e8res \u00e0 soupe",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "cuiller\u00e9e \u00e0 soupe",
                "cuill\u00e8res \u00e0 soupe",
                "c. \u00e0 s.",
                "c. a s.",
                "c. \u00e0 s..",
                "c. a s.."
            ],
            "milliliters": 14.786764782728676,
            "translations": {
                "en": {
                    "singular": "tablespoon",
                    "plural": "tablespoons"
                }
            }
        },
        {
            "description": "tasse",
            "plural": "tasses",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "tasse",
                "tasses",
                "tasse emball\u00e9e",
                "tasse pr\u00e9par\u00e9e"
            ],
            "milliliters": 236.5882365,
            "translations": {
                "en": {
                    "singular": "cup",
                    "plural": "cups"
                }
            }
        },
        {
            "description": "pinte",
            "plural": "pintes",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "pinte",
                "pintes",
                "pt",
                "pt.",
                "pts",
                "pts."
            ],
            "milliliters": 473.176,
            "translations": {
                "en": {
                    "singular": "pint",
                    "plural": "pints"
                }
            }
        },
        {
            "description": "quart",
            "plural": "quarts",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "quart",
                "quarts",
                "qt",
                "qt."
            ],
            "milliliters": 946.353,
            "translations": {
                "en": {
                    "singular": "quart",
                    "plural": "quart"
                }
            }
        },
        {
            "description": "gallon",
            "plural": "gallons",
            "units_mode": "english",
            "type": "volume",
            "alts": [
                "gallon",
                "gallons",
                "gal",
                "gal."
            ],
            "milliliters": 3785.41,
            "translations": {
                "en": {
                    "singular": "gallon",
                    "plural": "gallons"
                }
            }
        },
        {
            "description": "millilitre",
            "plural": "millilitres",
            "units_mode": "metric",
            "type": "volume",
            "alts": [
                "millilitre",
                "millilitres",
                "milliliter",
                "milliliters",
                "ml",
                "ml."
            ],
            "milliliters": 1,
            "translations": {
                "en": {
                    "singular": "milliliter",
                    "plural": "milliliters"
                }
            }
        },
        {
            "description": "centilitre",
            "plural": "centilitres",
            "units_mode": "metric",
            "type": "volume",
            "alts": [
                "centilitre",
                "centilitres",
                "cl",
                "cl."
            ],
            "milliliters": 100,
            "translations": {
                "en": {
                    "singular": "centiliter",
                    "plural": "centiliters"
                }
            }
        },
        {
            "description": "litre",
            "plural": "litres",
            "units_mode": "metric",
            "type": "volume",
            "alts": [
                "litre",
                "litres",
                "liter",
                "liters",
                "l",
                "l."
            ],
            "milliliters": 1000,
            "translations": {
                "en": {
                    "singular": "liter",
                    "plural": "liters"
                }
            }
        },
        {
            "description": "kilogramme",
            "plural": "kilogrammes",
            "units_mode": "metric",
            "type": "mass",
            "alts": [
                "kilogramme",
                "kilogrammes",
                "kilogram",
                "kilograms",
                "kg",
                "kg."
            ],
            "grams": 1000,
            "translations": {
                "en": {
                    "singular": "kilogram",
                    "plural": "kilograms"
                }
            }
        },
        {
            "description": "gramme",
            "plural": "grammes",
            "units_mode": "metric",
            "type": "mass",
            "alts": [
                "gramme",
                "grammes",
                "gram",
                "grams",
                "grm",
                "grm.",
                "g",
                "g."
            ],
            "grams": 1,
            "translations": {
                "en": {
                    "singular": "gram",
                    "plural": "grams"
                }
            }
        },
        {
            "description": "livre",
            "plural": "livres",
            "units_mode": "english",
            "type": "weight",
            "alts": [
                "livre",
                "livres",
                "lb",
                "lbs",
                "lb.",
                "lbs."
            ],
            "acceleration": 9.80665,
            "grams": 453.592,
            "translations": {
                "en": {
                    "singular": "pound",
                    "plural": "pounds"
                }
            }
        },
        {
            "description": "once",
            "plural": "onces",
            "units_mode": "english",
            "type": "weight",
            "alts": [
                "once",
                "onces",
                "ounce",
                "ounces",
                "oz",
                "oz.",
                "onz",
                "oz de m\u00e9lange",
                "once de m\u00e9lange"
            ],
            "acceleration": 9.80665,
            "grams": 28.3495,
            "translations": {
                "en": {
                    "singular": "ounce",
                    "plural": "ounces"
                }
            }
        }
    ]
};
