'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Parser } from 'expr-eval';

import AddNutrients from './Prescription/AddNutrients.react';
import RemoveNutrients from './Prescription/RemoveNutrients.react';
import EnvelopeEditor from './Prescription/EnvelopeEditor.react';
import AddConditions from './Prescription/AddConditions.react';
import AddMealRx from './Prescription/AddMealRx.react';
import DateSelector from '../../Widgets/DateSelector.react';
import Select from '../../Widgets/Select.react';
import MyTabs from '../../../../components/Widgets/Tabs.react';

import UserStore from '../../../../stores/UserStore';

import allNutrients from '../../../../tables/nutrients';
import { computePrescriptionFromConditions } from '../../../utils/Patients';
import { getDemographic, getDistributionMultiplier, computeDefaultMealRx } from '../../../../utils/Nutrition';
import { roundForHumans } from '../../../../utils/Math';
import allRecommendations from '../../../../tables/recommendations';

import './Prescription.scss';

export default class Prescription extends Component {
    static propTypes = {
        isMealPlanWizard: PropTypes.bool,
    };

    static defaultProps = {
        isMealPlanWizard: false
    };

    static contextTypes = {
        user: PropTypes.object,
        showUpgradeForm: PropTypes.func
    };

    constructor(props) {
        super(props);
        const { patient = {} } = props;

        const { prescriptions = [], next_update } = patient;

        const allDay    = prescriptions.filter(p => p.meal_type === 'all-day')[0] || {meal_type: 'all-day', envelope: {}},
              breakfast = prescriptions.filter(p => p.meal_type === 'Breakfast')[0],
              lunch     = prescriptions.filter(p => p.meal_type === 'Lunch')[0],
              dinner    = prescriptions.filter(p => p.meal_type === 'Dinner')[0],
              snack     = prescriptions.filter(p => p.meal_type === 'Snack')[0];

        // Should mode be all day or meals?
        const { breakfasts, lunches, dinners } = (patient.preferences || {})
        const mode = 'all-day';

        // We only care about the conflicts from the conditions.
        const conditionNames = patient.conditions.map(cd => cd.name);
        const { conflicts } = computePrescriptionFromConditions(patient, conditionNames);

        let expiresIn = next_update
                      ? moment(next_update).diff(moment().format('YYYY-MM-DD'), 'days')
                      : 0;

        this.state = {
            mode,
            editMode: true,

            // Make deep clones of all prescriptions, so if user closes dialog the values aren't updated.
            allDay: JSON.parse(JSON.stringify(allDay)),
            breakfast: this.fillDists(allDay, breakfast),
            lunch: this.fillDists(allDay, lunch),
            dinner: this.fillDists(allDay, dinner),
            snack: this.fillDists(allDay, snack),

            conflicts,
            error: '',
            nutrientErrors: [],

            dirty: false,
            expiresIn,
            next_update: patient.next_update,
        };
    }

    /**
     * Function fills in missing dist_min and dist_max on the per-meal prescription.
     * If these are not present, then changing the all-day prescription will not automatically
     * change the per-meal limits
     */
    fillDists = (allDay, rx) => {
        if (!(rx && rx.envelope)) {
            return null;
        }

        // Make a deep copy
        rx = JSON.parse(JSON.stringify(rx));

        // Loop through all the nutrients and make sure their dist_min and dist_max's are
        // up-to-date. We don't want any bad data giving us a hard time :)
        Object.keys(rx.envelope).forEach(nutrNo => {
            const range = rx.envelope[nutrNo];
            const allDayRange = allDay.envelope[nutrNo];

            if (!allDayRange) {
                return;
            }

            if (range.min && allDayRange.min) {
                range.dist_min = Math.round(range.min / allDayRange.min * 100) / 100;
            }

            if (range.max && allDayRange.max) {
                range.dist_max = Math.round(range.max / allDayRange.max * 100) / 100;
            }
        });

        return rx;
    }

    isDirty = () => {
        return this.state.dirty;
    }

    validatePrescription = (rx, mealType) => {
        const { envelope } = rx;

        // Make sure that none of the minimums are greater than the maximums
        let judgement = false;

        Object.keys(envelope).forEach(nutrNo => {
            const range = envelope[nutrNo];

            if (!judgement && range.min && range.max && range.min >= range.max) {
                judgement = {
                    nutrientErrors: [nutrNo],
                    message: 'No minimum can be more than the maximum',
                };
            }
        });

        if (judgement !== false) {
            return judgement;
        }

        const kcal = envelope['208'],
              pro  = envelope['203'],
              fat  = envelope['204'],
              cho  = envelope['205'];

        if (!(kcal && pro && fat && cho)) {
            return false;
        }

        // Do we have all the values we need to validate?
        if (kcal.max && pro.max && fat.max && cho.max &&
            kcal.min && pro.min && fat.min && cho.min) {

            const macros = {
                min: pro.min * 4 + fat.min * 9 + cho.min * 4,
                max: pro.max * 4 + fat.max * 9 + cho.max * 4,
            };

            const overlap = Math.max(0, Math.min(kcal.max, macros.max) - Math.max(kcal.min, macros.min));
            const diff = kcal.max - kcal.min;

            if (overlap < diff * 0.75) {
                return {
                    nutrientErrors: ['203', '204', '205', '208'],
                    message: <span>
                                Macro calories must add up to {mealType} calories
                             </span>,
                };
            }
        }
    }

    validate = () => {
        const { conflicts, allDay, breakfast, lunch, dinner, snack, expiresIn } = this.state;

        const hasAllDayConflicts = this.hasConflicts(conflicts['all-day'], allDay),
              hasBreakfastConflicts = this.hasConflicts(conflicts.Breakfast, breakfast),
              hasLunchConflicts = this.hasConflicts(conflicts.Lunch, lunch),
              hasDinnerConflicts = this.hasConflicts(conflicts.Dinner, dinner),
              hasSnackConflicts = this.hasConflicts(conflicts.Snack, snack),
              hasMealConflicts = hasBreakfastConflicts || hasLunchConflicts || hasDinnerConflicts || hasSnackConflicts,
              hasConflicts = hasAllDayConflicts || hasMealConflicts;

        if (hasAllDayConflicts) {
            return 'There are unresolved conflicts on the Daily Totals.';
        }

        if (hasBreakfastConflicts) {
            return 'There are unresolved conflicts in the Breakfast Limits';
        }

        if (hasLunchConflicts) {
            return 'There are unresolved conflicts in the Lunch Limits';
        }

        if (hasDinnerConflicts) {
            return 'There are unresolved conflicts in the Dinner Limits';
        }

        // If we have a next update, it must be in the future
        const { practice_type } = UserStore.getUser();
        if (expiresIn && practice_type === 'dietetics') {
            if (expiresIn < 0) {
                this.setState({error: 'expires-in'});

                return 'Prescription is expired! Please update the prescription expiration.';
            }
        }


        let isValid;

        if (allDay && (isValid = this.validatePrescription(allDay, 'Daily Totals'))) {
            this.setState({mode: 'all-day', nutrientErrors: isValid.nutrientErrors});

            return isValid.message;
        }

        if (breakfast && (isValid = this.validatePrescription(breakfast, 'Breakfast'))) {
            this.setState({mode: 'all-day', nutrientErrors: isValid.nutrientErrors});

            return isValid.message;
        }

        if (lunch && (isValid = this.validatePrescription(lunch, 'Lunch'))) {
            this.setState({mode: 'all-day', nutrientErrors: isValid.nutrientErrors});

            return isValid.message;
        }

        if (dinner && (isValid = this.validatePrescription(dinner, 'Dinner'))) {
            this.setState({mode: 'all-day', nutrientErrors: isValid.nutrientErrors});

            return isValid.message;
        }

        this.setState({nutrientErrors: [], error: null});

        return true;
    }

    mutate = (patient) => {
        const { allDay, breakfast, lunch, dinner, snack, expiresIn } = this.state;

        [allDay, breakfast, lunch, dinner, snack].filter(v => v).forEach(rx => {
            Object.keys(rx.envelope).forEach(nutrNo => {
                // Convert strings to floats, delete elements if string length is empty.
                const range = rx.envelope[nutrNo];

                if (typeof range.min === 'string' && range.min.length === 0) {
                    delete range.min;
                }

                if (typeof range.max === 'string' && range.max.length === 0) {
                    delete range.max;
                }
            });
        });

        patient.next_update = expiresIn ? moment().add(expiresIn, 'days').format('YYYY-MM-DD') : '';
        patient.prescriptions = [allDay, breakfast, lunch, dinner, snack].filter(v => v);

        patient.completed = patient.completed || [];

        if (!patient.completed.includes('nutrition')) {
            patient.completed.push('nutrition');
        }

        return patient;
    }

    onChangeAllDayEnvelope = (envelope, nutrNo = '') => {
        const { allDay, breakfast, lunch, dinner, snack } = this.state;

        allDay.envelope = envelope;

        // Do we need to update values from the distribution percentages?
        [breakfast, lunch, dinner, snack].filter(v => v).forEach(mealRx => {
            if (mealRx.envelope[nutrNo].dist_min) {
                mealRx.envelope[nutrNo].min = roundForHumans(allDay.envelope[nutrNo].min * mealRx.envelope[nutrNo].dist_min);
            }

            if (mealRx.envelope[nutrNo].dist_max) {
                mealRx.envelope[nutrNo].max = roundForHumans(allDay.envelope[nutrNo].max * mealRx.envelope[nutrNo].dist_max);
            }
        });

        this.setState({allDay, breakfast, lunch, dinner, snack, dirty: true});
    }

    safeUpdateDists = (allDay, envelope, nutrNo) => {
        const range = envelope[nutrNo],
              allDayRange = (allDay && allDay.envelope && allDay.envelope[nutrNo]);

        if (!range || !allDayRange) {
            return envelope;
        }

        if (range.min && allDayRange.min) {
            range.dist_max = Math.round(range.min / allDayRange.min * 100) / 100;
        }

        if (range.max && allDayRange.max) {
            range.dist_max = Math.round(range.max / allDayRange.max * 100) / 100;
        }

        return envelope;
    }



    onAddBreakfastRx = () => {
        this.setState({breakfast: computeDefaultMealRx(this.state.allDay, 'Breakfast'), dirty: true});
    }

    onChangeBreakfastEnvelope = (envelope, nutrNo = '') => {
        const { allDay, breakfast } = this.state;

        breakfast.envelope = this.safeUpdateDists(allDay, envelope, nutrNo);

        this.setState({breakfast, dirty: true});
    }

    onRemoveBreakfastRx = () => {
        this.setState({breakfast: null, dirty: true});
    }



    onAddLunchRx = () => {
        this.setState({lunch: computeDefaultMealRx(this.state.allDay, 'Lunch'), dirty: true});
    }

    onChangeLunchEnvelope = (envelope, nutrNo = '') => {
        const { allDay, lunch } = this.state;

        lunch.envelope = this.safeUpdateDists(allDay, envelope, nutrNo);

        this.setState({lunch, dirty: true});
    }

    onRemoveLunchRx = () => {
        this.setState({lunch: null, dirty: true});
    }



    onAddDinnerRx = () => {
        this.setState({dinner: computeDefaultMealRx(this.state.allDay, 'Dinner'), dirty: true});
    }

    onChangeDinnerEnvelope = (envelope, nutrNo = '') => {
        const { allDay, dinner } = this.state;

        dinner.envelope = this.safeUpdateDists(allDay, envelope, nutrNo);

        this.setState({dinner, dirty: true});
    }

    onRemoveDinnerRx = () => {
        this.setState({dinner: null, dirty: true});
    }



    onAddSnackRx = () => {
        this.setState({snack: computeDefaultMealRx(this.state.allDay, 'Snack'), dirty: true});
    }

    onChangeSnackEnvelope = (envelope, nutrNo = '') => {
        const { allDay, snack } = this.state;

        snack.envelope = this.safeUpdateDists(allDay, envelope, nutrNo);

        this.setState({snack, dirty: true});
    }

    onRemoveSnackRx = () => {
        this.setState({snack: null, dirty: true});
    }

    onAddNutrients = (nutrNos) => {
        const { allDay, breakfast, lunch, dinner, snack } = this.state;
        const { patient } = this.props;
        const { target_energy_kcal } = patient;

        // Determine the all-day DRI value (if available)
        const demographic = getDemographic(patient);
        const dri = {
            ...allRecommendations['all-ages'],
            ...allRecommendations[demographic]
        };

        const macros = {
            '203': {per_min: 0.15, per_max: 0.30},
            '204': {per_min: 0.25, per_max: 0.35},
            '205': {per_min: 0.45, per_max: 0.55},
        };

        let kcal_min = (allDay && allDay.envelope['208'] && allDay.envelope['208'].min) ||
                       Math.round((target_energy_kcal - 100) / 5) * 5,
            kcal_max = (allDay && allDay.envelope['208'] && allDay.envelope['208'].max) ||
                       Math.round((target_energy_kcal + 100) / 5) * 5;

        nutrNos.forEach(nutrNo => {
            const range = {};
            const nutrient = allNutrients[nutrNo];

            if (!nutrient) {
                return;
            }

            if (nutrNo === '208') {
                range.min = kcal_min;
                range.max = kcal_max;
            } else if (macros[nutrNo]) {
                range.min = roundForHumans(kcal_min * macros[nutrNo].per_min / allNutrients[nutrNo].calories_per_gram);
                range.max = roundForHumans(kcal_max * macros[nutrNo].per_max / allNutrients[nutrNo].calories_per_gram);
                range.per_min = macros[nutrNo].per_min;
                range.per_max = macros[nutrNo].per_max;
            } else if (dri[nutrNo]) {
                if (nutrient.envelope === 'above-80p') {
                    range.min = dri[nutrNo];
                } else if (nutrient.envelope === 'below-120p') {
                    range.max = dri[nutrNo];
                } else if (nutrient.envelope === 'within-20p') {
                    range.min = roundForHumans(dri[nutrNo] * 0.8);
                    range.max = roundForHumans(dri[nutrNo] * 1.2);
                }
            }

            allDay.envelope[nutrNo] = range;
        });

        // If we have per-meal prescriptions, add the nutrient to those as well, populating
        // the values and dist_min and dist_max's
        [breakfast, lunch, dinner, snack].filter(v => v).forEach(rx => {
            const implicitRx = computeDefaultMealRx(allDay, rx.meal_type);

            nutrNos.forEach(nutrNo => {
                rx.envelope[nutrNo] = implicitRx.envelope[nutrNo];
            });
        });

        this.setState({allDay, breakfast, lunch, dinner, dirty: true});
    }

    onRemoveNutrients = (nutrNos) => {
        const { allDay, breakfast, lunch, dinner, snack } = this.state;

        [allDay, breakfast, lunch, dinner, snack].filter(v => v).forEach(rx => {
            nutrNos.forEach(nutrNo => {
                delete rx.envelope[nutrNo];
            });
        });

        this.setState({allDay, breakfast, lunch, dinner, dirty: true});
    }

    hasConflicts = (conflicts, rx) => {
        if (!conflicts || !rx) {
            return false;
        }

        if (Object.keys(conflicts).length == 0) {
            return false;
        }

        // Count how many of the conflicts are resolved.
        let resolved = 0;

        Object.keys(conflicts).forEach(nutrNo => {
            const conflict = conflicts[nutrNo];

            // Do we have values for this conflict?
            const range = rx.envelope[nutrNo] || {};

            if (range.min || range.max) {
                resolved++;
            }
        });

        return Object.keys(conflicts).length !== resolved;
    }

    toggleEditMode = () => {
        const { editMode } = this.state;

        this.setState({editMode: !editMode});

        return !editMode;
    }

    isEditMode = () => {
        return this.state.editMode;
    }

    setRxExpires = (expiresIn) => {
        this.setState({expiresIn});
    }

    renderFooter = () => {
        const { isMealPlanWizard } = this.props;
        const { allDay, expiresIn, error } = this.state;
        const { user, showUpgradeForm } = this.context;
        const { capabilities = {}, practice_type } = user || {};

        let canExpire = capabilities.edit_micros;

        let expiresInOpts = [
            {label: 'no review needed', value: 0},
            {label: '1 week', value: 7},
            {label: '2 weeks', value: 7 * 2},
            {label: '3 weeks', value: 7 * 3},
            {label: '4 weeks', value: 7 * 4},
            {label: '5 weeks', value: 7 * 5},
            {label: '6 weeks', value: 7 * 6},
            {label: '8 weeks', value: 7 * 8},
            {label: '10 weeks', value: 7 * 10},
            {label: '12 weeks', value: 7 * 12},
            {label: '24 weeks', value: 7 * 24},
            {label: '52 weeks', value: 7 * 52},
        ];

        // Do we have a next_update value already? Make sure we can select it.
        if (expiresIn != 0 && !expiresInOpts.find(o => o.value == expiresIn)) {
            if (expiresIn < 0) {
                const expiredAgo = Math.abs(expiresIn);
                expiresInOpts.push({
                    label: 'EXPIRED ' + expiredAgo + (expiredAgo == 1 ? ' day ago' : ' days ago'),
                    value: expiresIn,
                });
            } else if (!expiresInOpts.filter(o => o.value == expiresIn)[0]) {
                expiresInOpts.push({
                    label: expiresIn + (expiresIn == 1 ? ' day' : ' days'),
                    value: expiresIn,
                });
            }

            expiresInOpts = expiresInOpts.sort((a, b) => a.value - b.value);
        }

        return (
            <footer>
                {user.practice_type !== 'fitness' ?
                    (<div>
                        <AddNutrients onAddNutrients={this.onAddNutrients}
                            currentNutrients={Object.keys(allDay.envelope)} />
                        <RemoveNutrients onRemoveNutrients={this.onRemoveNutrients}
                            currentNutrients={Object.keys(allDay.envelope)} />
                    </div>)
                : null}

                {user.practice_type !== 'fitness' && !isMealPlanWizard ?
                    (<div className="with-label expires-in" data-error={error === 'expires-in'}>
                        <label>Review Prescription in:</label>
                        <Select value={expiresIn}
                            placeholder="Choose expiration..."
                            onChange={canExpire ? expiresIn => this.setRxExpires(expiresIn) : () => showUpgradeForm({feature: 'edit_micros'}) }
                            options={expiresInOpts}>
                        </Select>
                    </div>)
                : null}

            </footer>
        )
    }

    render() {
        const { patient } = this.props;
        const { mode, editMode, conflicts, allDay, breakfast, lunch, dinner, snack, expiresIn, next_update,
                error, nutrientErrors } = this.state;

        const riskLevelOpts = [
            {label: 'Low', value: 'low'},
            {label: 'Medium', value: 'medium'},
            {label: 'High', value: 'high'},
        ];

        const hasAllDayConflicts = this.hasConflicts(conflicts['all-day'], allDay),
              hasBreakfastConflicts = this.hasConflicts(conflicts.Breakfast, breakfast),
              hasLunchConflicts = this.hasConflicts(conflicts.Lunch, lunch),
              hasDinnerConflicts = this.hasConflicts(conflicts.Dinner, dinner),
              hasSnackConflicts = this.hasConflicts(conflicts.Snack, snack),
              hasMealConflicts = hasBreakfastConflicts || hasLunchConflicts || hasDinnerConflicts || hasSnackConflicts,
              hasConflicts = hasAllDayConflicts || hasMealConflicts;

        const { practice_type } = UserStore.getUser();

        const canChangePerMeal = practice_type === 'fitness' ? false : true;

        return (
            <div className="edit-nutrition-prescription patient-form">
                <div className="envelope-editors"
                    data-conflicts={hasConflicts}
                    data-all-day-conflicts={hasAllDayConflicts}
                    data-meals-conflicts={hasMealConflicts}
                    data-breakfast-conflicts={hasBreakfastConflicts}
                    data-lunch-conflicts={hasLunchConflicts}
                    data-dinner-conflicts={hasDinnerConflicts}>

                    <MyTabs defaultTab="Daily Totals">
                        <section data-title="Daily Totals">
                            <div>
                                <EnvelopeEditor showPercents={true}
                                    mealType="all-day"
                                    data-edit-mode={editMode}
                                    patient={patient}
                                    envelope={allDay.envelope}
                                    nutrientErrors={nutrientErrors}
                                    conflicts={conflicts['all-day'] || {}}
                                    onChangeEnvelope={this.onChangeAllDayEnvelope} />
                                {this.renderFooter()}
                             </div>
                        </section>

                        {canChangePerMeal ?
                            <section data-title="Breakfast">
                                {breakfast ?
                                    <div>
                                        <EnvelopeEditor patient={patient}
                                            showPercents
                                            title="Breakfast Nutrition"
                                            data-edit-mode={editMode}
                                            mealType="Breakfast"
                                            envelope={breakfast.envelope}
                                            nutrientErrors={nutrientErrors}
                                            conflicts={conflicts.Breakfast || {}}
                                            onRemoveRx={this.onRemoveBreakfastRx}
                                            onChangeEnvelope={this.onChangeBreakfastEnvelope} />
                                        {this.renderFooter()}
                                     </div>
                                : <AddMealRx mealType="Breakfast" addMealRx={this.onAddBreakfastRx} />}
                            </section>
                        : <span />}

                        {canChangePerMeal ?
                            <section data-title="Lunch">
                                {lunch ?
                                    <div>
                                        <EnvelopeEditor patient={patient}
                                            showPercents
                                            title="Lunch Nutrition"
                                            mealType="Lunch"
                                            data-edit-mode={editMode}
                                            envelope={lunch.envelope}
                                            nutrientErrors={nutrientErrors}
                                            conflicts={conflicts.Lunch || {}}
                                            onRemoveRx={this.onRemoveLunchRx}
                                            onChangeEnvelope={this.onChangeLunchEnvelope} />
                                        {this.renderFooter()}
                                    </div>
                                : <AddMealRx mealType="Lunch" addMealRx={this.onAddLunchRx} />}
                            </section>

                        : <span />}

                        {canChangePerMeal ?
                            <section data-title="Dinner">
                                {dinner ?
                                    <div>
                                        <EnvelopeEditor patient={patient}
                                            showPercents
                                            title="Dinner Nutrition"
                                            mealType="Dinner"
                                            data-edit-mode={editMode}
                                            envelope={dinner.envelope}
                                            nutrientErrors={nutrientErrors}
                                            conflicts={conflicts.Dinner || {}}
                                            onRemoveRx={this.onRemoveDinnerRx}
                                            onChangeEnvelope={this.onChangeDinnerEnvelope} />
                                        {this.renderFooter()}
                                    </div>
                                : <AddMealRx mealType="Dinner" addMealRx={this.onAddDinnerRx} />}
                            </section>
                        : <span />}

                        {canChangePerMeal ?
                            <section data-title="Snack">
                                <p className="explainer">Snack prescription applies to all snacks combined for the day and can only be limited to maximums.</p>
                                {snack ?
                                    <div>
                                        <EnvelopeEditor patient={patient}
                                            title="Snack Nutrition"
                                            mealType="Snack"
                                            data-edit-mode={editMode}
                                            envelope={snack.envelope}
                                            nutrientErrors={nutrientErrors}
                                            conflicts={conflicts.Snack || {}}
                                            onRemoveRx={this.onRemoveSnackRx}
                                            onChangeEnvelope={this.onChangeSnackEnvelope} />
                                        {this.renderFooter()}
                                    </div>
                                : <AddMealRx mealType="Snack" addMealRx={this.onAddSnackRx} />}
                            </section>
                        : <span />}
                    </MyTabs>
                </div>
            </div>
        );
    }
}
